export default {
  name: "i-table",
  props: ["tableData", "disabled"],
  computed: {
    spanArr() {
      let arr = [];
      let pos = 0;
      for (var i = 0; i < this.tableData.length; i++) {
        if (i === 0) {
          arr.push(1);
          pos = 0;
        } else {
          if (this.tableData[i].scene === this.tableData[i - 1].scene) {
            arr[pos] += 1;
            arr.push(0);
          } else {
            // 不相等push 1
            arr.push(1);
            pos = i;
          }
        }
      }
      return arr;
    },
  },
  methods: {
    objectSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.spanArr[rowIndex];
        return {
          rowspan: _row,
          colspan: _row > 0 ? 1 : 0,
        };
      }
    },
  },
};
