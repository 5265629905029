import ITable from "@component/ITable/index.vue";
import { queryConfig, saveConfig } from "@lib";
import { mapGetters } from "vuex";
export default {
  components: {
    [ITable.name]: ITable,
  },
  data() {
    return {
      isEdit: false,
      tableData0: {
        token: "",
        authUrl: "",
      },
      tableData1: [
        {
          scene: "租户",
          name: "新增",
          key: "tenementAddUrl",
          path: "",
        },
        {
          scene: "租户",
          name: "修改",
          key: "tenementUpdateUrl",
          path: "",
        },
        {
          scene: "租户",
          name: "删除",
          key: "tenementDelUrl",
          path: "",
        },
        {
          scene: "用户",
          name: "新增",
          key: "userAddUrl",
          path: "",
        },
        {
          scene: "用户",
          name: "修改",
          key: "userUpdateUrl",
          path: "",
        },
        {
          scene: "用户",
          name: "删除",
          key: "userDelAddUrl",
          path: "",
        },
        {
          scene: "角色",
          name: "新增",
          key: "roleAddUrl",
          path: "",
        },
        {
          scene: "角色",
          name: "修改",
          key: "roleUpdateUrl",
          path: "",
        },
        {
          scene: "角色",
          name: "删除",
          key: "roleDelUrl",
          path: "",
        },
        {
          scene: "机构部门",
          name: "新增",
          key: "orgAddUrl",
          path: "",
        },
        {
          scene: "机构部门",
          name: "修改",
          key: "orgUpdateUrl",
          path: "",
        },
        {
          scene: "机构部门",
          name: "删除",
          key: "orgDelUrl",
          path: "",
        },
      ],
      tableData2: [
        {
          scene: "用户角色授权",
          name: "新增",
          key: "userRoleAddUrl",
          path: "",
        },
        {
          scene: "用户角色授权",
          name: "修改",
          key: "userRoleUpdateUrl",
          path: "",
        },
        {
          scene: "用户角色授权",
          name: "删除",
          key: "userRoleDelUrl",
          path: "",
        },
        {
          scene: "租户应用授权",
          name: "新增",
          key: "tenementRoleAddUrl",
          path: "",
        },
        {
          scene: "租户应用授权",
          name: "修改",
          key: "tenementRoleUpdateUrl",
          path: "",
        },
        {
          scene: "租户应用授权",
          name: "删除",
          key: "tenementRoleDelUrl",
          path: "",
        },
        {
          scene: "用户功能授权",
          name: "新增",
          key: "userDelUrl",
          path: "",
        },
        {
          scene: "用户功能授权",
          name: "修改",
          key: "userUpdateUrl",
          path: "",
        },
        {
          scene: "用户功能授权",
          name: "删除",
          key: "userDelUrl",
          path: "",
        },
      ],
    };
  },
  created() {
    this.getConfig();
  },
  computed: {
    ...mapGetters(["tenement"]),
    formData() {
      let cache = {};
      this.tableData1.forEach((m) => {
        cache[m.key] = m.path;
      });
      this.tableData2.forEach((m) => {
        cache[m.key] = m.path;
      });
      return cache;
    },
  },
  methods: {
    async getConfig() {
      try {
        let { status, result } = await queryConfig({
          tenementId: this.tenement?.id,
        });
        if (status === "success" && result.length) {
          for (let i in this.tableData0) {
            this.tableData0[i] = result[0][i] || "";
          }
          this.tableData1.forEach((m) => {
            m.path = result[0][m.key] || "";
          });
          this.tableData2.forEach((m) => {
            m.path = result[0][m.key] || "";
          });
        } else {
          this.$message.error(result?.error_msg);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async onSubmit() {
      try {
        let { status, result } = await saveConfig({
          tenementId: this.tenement?.id,
          ...this.tableData0,
          ...this.formData,
        });
        if (status === "success") {
          this.isEdit = false;
          this.getConfig();
          this.$message.success("保存成功");
        } else {
          this.$message.error(result?.error_msg);
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
